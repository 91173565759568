import { Card, Col, Row, Statistic } from "antd";
import React from "react";
import { useDollarRequest } from "../Hooks/dollar/useDollar.query";
import {
  useManagerWalletFetchRequest,
  useWalletAmountBalanceFetchRequest,
  useWalletExpenseBalanceFetchRequest,
} from "../Hooks/wallet/useWallet.query";
import { useInvestorsFetchRequest } from "./../Hooks/investor/useInvestor.query";
import { formatCurrency, mathDollarRtx } from "./../helpers/moneyFormat";

const Home = () => {
  const { data: dataInvestors, isLoading } =
    useInvestorsFetchRequest("profile, wallet");
  const investor = dataInvestors?.data?.data;
  const { data: dataAmount } = useWalletAmountBalanceFetchRequest("amount");
  const { data: dataExpense } = useWalletExpenseBalanceFetchRequest("expense");
  const { data: managerWallet } = useManagerWalletFetchRequest();
  const { data: dollar } = useDollarRequest();

  const wallet = managerWallet?.data?.data;
  if (isLoading) {
    return "carregando...";
  }

  return (
    <div className="layout-content">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <Statistic
              title={`Cotação do Dolar - RTX `}
              value={`USD 1,00 / ${mathDollarRtx(dollar?.bid)}`}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <Statistic
              title="Total da carteira"
              value={formatCurrency(wallet?.amount)}
              valueStyle={{
                color: "#3f8600",
              }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <Statistic
              title="Valores repassados"
              value={formatCurrency(wallet?.expense)}
              valueStyle={{
                color: "#cf1322",
              }}
            />
          </Card>
        </Col>

        <Col xs={24} sm={24} md={6} lg={6} xl={6} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <Statistic
              title="Total de usuários na carteira"
              value={investor?.length}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} className="mb-24">
          {/* <Card bordered={false} className="criclebox h-full">
            <Statistic title="Quantidade de G.N" value={10} />
          </Card> */}
        </Col>
      </Row>
    </div>
  );
};

export default Home;
