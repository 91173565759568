import { useQuery } from "@tanstack/react-query";
import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import api from "../../services/api";
import { Dashboard } from "../icons/Dashboard";
import { Profile } from "../icons/Profile";

function Sidenav(props) {
  const { color } = props;
  let { pathname } = useLocation();
  const page = pathname.split("/");

  pathname = pathname.replace("/", "");

  const { data: accessGiven } = useQuery(["access", 1], () =>
    api
      .get(`${process.env.REACT_APP_API}/site/get-access`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      })
      .then((res) => {
        // if (!res.data) {
        //   localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
        //   navigate("/login");
        // }
        return res?.data;
      })
      .catch((err) => console.log("error", err))
  );

  const MenuItems = [
    {
      title: "Dashboard",
      link: "painel",
      icon: <Dashboard color={color} />,
      display: accessGiven >= 50,
    },
    {
      title: "Minha carteira",
      link: "investidor",
      icon: <Profile />,
      display: accessGiven >= 50,
    },
    {
      title: "Simulador",
      link: "simulator",
      icon: "$",
      display: accessGiven >= 50,
    },
  ];

  let activePage = null;
  if (page.length === 1) {
    activePage = page[0];
  }
  if (page.length > 1) {
    activePage = page[1];
  }

  return (
    <>
      <div className="brand">
        <img src={logo} alt="" style={{ height: "60px" }} />
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        {MenuItems.map((menu, index) => {
          return (
            menu.display && (
              <Menu.Item key={index}>
                <NavLink to={`${menu.link}`}>
                  <span
                    className="icon"
                    style={{
                      background: activePage === `${menu.link}` ? color : "",
                    }}
                  >
                    {menu.icon}
                  </span>
                  <span className="label">{menu.title}</span>
                </NavLink>
              </Menu.Item>
            )
          );
        })}
      </Menu>
    </>
  );
}

export default Sidenav;
