import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getDollarNow = async () => {
  try {
    const data = await axios.get(
      `https://economia.awesomeapi.com.br/json/last/USD`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data.data.USDBRL;
  } catch (error) {
    console.log(error.message);
  }
};

export function useDollarRequest() {
  return useQuery(["dollarRequest"], () => getDollarNow(), {
    onSuccess: (data) => {
      return data;
    },
  });
}
