import { Affix, Drawer, Layout } from 'antd'
import { useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'
import Sidenav from './Sidenav'

const { Header: AntHeader, Content, Sider } = Layout

function Main({ children }) {
  const [visible, setVisible] = useState(false)
  // const [placement, setPlacement] = useState('right')
  const [sidenavColor, setSidenavColor] = useState('#1890ff')
  const [sidenavType, setSidenavType] = useState('transparent')
  // const [fixed, setFixed] = useState(false)

  const openDrawer = () => setVisible(!visible)
  const handleSidenavType = (type) => setSidenavType(type)
  const handleSidenavColor = (color) => setSidenavColor(color)
  // const handleFixedNavbar = (type) => setFixed(type)

  let { pathname } = useLocation()
  pathname = pathname.replace('/', '')
  const splitPathname = pathname.split('/')

  // useEffect(() => {
  //   if (pathname === 'rtl') {
  //     setPlacement('left')
  //   } else {
  //     setPlacement('right')
  //   }
  // }, [pathname])

  return (
    <Layout
      className={`layout-dashboard ${
        splitPathname[2] === 'perfil' ? 'layout-profile' : ''
      } ${pathname === 'rtl' ? 'layout-dashboard-rtl' : ''}`}
    >
      <Drawer
        title={false}
        placement="left"
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        key="left"
        width={250}
        className={`drawer-sidebar`}
      >
        <Layout
          className={`layout-dashboard ${
            pathname === 'rtl' ? 'layout-dashboard-rtl' : ''
          }`}
        >
          <Sider
            trigger={null}
            width={250}
            theme="light"
            className={`sider-primary ant-layout-sider-primary ${
              sidenavType === '#fff' ? 'active-route' : ''
            }`}
            style={{ background: sidenavType }}
          >
            <Sidenav color={sidenavColor} />
          </Sider>
        </Layout>
      </Drawer>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type)
        }}
        trigger={null}
        width={250}
        theme="light"
        className={`sider-primary ant-layout-sider-primary ${
          sidenavType === '#fff' ? 'active-route' : ''
        }`}
        style={{ background: sidenavType }}
      >
        <Sidenav color={sidenavColor} />
      </Sider>
      <Layout>
        <Affix>
          <AntHeader className="ant-header-fixed">
            <Header
              onPress={openDrawer}
              name={pathname}
              subName={pathname}
              handleSidenavColor={handleSidenavColor}
              handleSidenavType={handleSidenavType}
            />
          </AntHeader>
        </Affix>
        <Content className="content-ant">
          <Outlet />
        </Content>
        <Footer />
      </Layout>
    </Layout>
  )
}

export default Main
