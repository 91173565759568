export const formatCurrency = (amount) => {
  const amounts = parseFloat(amount);
  // Use a expressão regular para adicionar pontos de milhar e vírgulas ao número
  const formattedAmount = amounts.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formattedAmount;
};

export const mathDollarRtx = (dollar) => {
  const result = parseFloat(dollar) + 0.05;
  return Intl.NumberFormat("pt-br", {
    style: "currency",
    currency: "BRL",
  }).format(result);
};
