import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const getManager = async (id) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/profile/manager-account?id=${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN,
          )}`,
        },
      },
    )
    return data
  } catch (error) {
    console.log(error.message)
  }
}

export function useManagerFetchRequest(id) {
  return useQuery(['manager', id], async () => await getManager(id), {
    enabled: !!id,
    retry: 5,
    onSuccess: (data) => {
      return data.data.data
    },
    onError: (error) => {
      console.log(error)
    },
  })
}
