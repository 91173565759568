import { useQuery } from "@tanstack/react-query";
import { Navigate, useRoutes } from "react-router-dom";
import Main from "../components/layout/Main";
import Home from "../pages/Home";
import SignIn from "../pages/SignIn";
import Simulator from "../pages/Simulator";
import User from "../pages/User";
import Users from "../pages/Users";
import CreateUser from "../pages/Users/create";
import api from "../services/api";
import { isAuthenticated } from "../services/auth";

export default function MainRouter() {
  // const navigate = useNavigate()
  const { data, loading } = useQuery(["access", 1], async () => {
    try {
      const response = await api.get(
        `${process.env.REACT_APP_API}/site/get-access`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              process.env.REACT_APP_ACCESS_TOKEN
            )}`,
          },
        }
      );
      // if (response.data.status === 400) {
      //   localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
      //   navigate("/login");
      // }
      return response.data;
    } catch (err) {
      console.log("error", err);
    }
  });
  return useRoutes([
    {
      path: "/",
      element: loading ? (
        "loading..."
      ) : isAuthenticated() ? (
        <Main />
      ) : (
        <Navigate to="/login" />
      ),
      children: [
        { element: <Navigate to="/painel" /> },
        { path: "/painel", element: <Home /> },
        data >= 50 && {
          path: "investidor",
          children: [
            { path: "", element: <Users /> },
            { path: "criar", element: data >= 99 ? <CreateUser /> : "401" },
            // { path: "editar/:id", element: data >= 99 ? <EditUser /> : "401" },
            { path: "perfil/:id", element: <User /> },
          ],
        },
        data >= 50 && {
          path: "simulator",
          children: [{ path: "", element: <Simulator /> }],
        },
        // data >= 99 && {
        //   path: "lancamento",
        //   children: [{ path: "", element: <RetroEntry /> }]
        // },
        // data >= 99 && {
        //   path: "lote",
        //   children: [{ path: "", element: <Batch /> }]
        // },
        // data >= 99 && {
        //   path: "role",
        //   children: [{ path: "", element: <Role /> }]
        // },
        // data >= 99 && {
        //   path: "saque",
        //   children: [{ path: "", element: <Withdrawal /> }]
        // },
        // data >= 99 && {
        //   path: "planos",
        //   children: [
        //     { path: "", element: <Plans /> },
        //     { path: "criar", element: <PlansCreate /> }
        //   ]
        // }
      ],
    },
    { path: "/login", element: <SignIn /> },
    { path: "*", element: "404" },
  ]);
}
