import { Modal } from 'antd'
import React from 'react'

const MyModal = ({
  isVisible = () => {},
  onCancel = () => {},
  title,
  children,
}) => {
  return (
    <Modal
      destroyOnClose
      title={title}
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
    >
      {children}
    </Modal>
  )
}

export default MyModal
