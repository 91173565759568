import { Button, Card, Col, Input, Row } from "antd";
import React, { useState } from "react";

const arrMonth = [
  "3.2",
  "3.2",
  "3.2",
  "3.2",
  "3.2",
  "3.2",
  "3.2",
  "3.2",
  "3.2",
  "3.2",
  "3.2",
  "3.2",
];

const Simulator = () => {
  const [valorInvestimento, setValorInvestimento] = useState("");
  const [mesesInvestimento, setMeseInvestimento] = useState(12);
  const [taxasMensais, setTaxasMensais] = useState(Array(12).fill(0));
  const [ganhosMensais, setGanhosMensais] = useState([]);
  const [ganhosAcumulados, setGanhosAcumulados] = useState([]);
  const [saldoGanhosAcumulados, setSaldoGanhosAcumulados] = useState([]);

  const handleChangeInvestimento = (event) => {
    setValorInvestimento(event.target.value);
  };

  const handleChangeMesesInvestimento = (event) => {
    setMeseInvestimento(event.target.value);
  };

  const handleChangeTaxaMensal = (event, index) => {
    const newTaxasMensais = [...taxasMensais];
    newTaxasMensais[index] = parseFloat(event.target.value);
    setTaxasMensais(newTaxasMensais);
  };

  const calcularGanhos = () => {
    let valorInvestido = parseFloat(valorInvestimento);
    let valorInvestidoParaGanhoAcumulado = parseFloat(valorInvestimento);

    const newGanhosMensais = [];
    const newGanhosAcumulados = [];
    const newSaldoGanhosAcumulados = [];

    for (let i = 0; i < mesesInvestimento; i++) {
      const ganhoMensalComSaque = valorInvestido * 0.031;

      const ganhoMensalAcumulado = valorInvestidoParaGanhoAcumulado * 0.031;
      valorInvestidoParaGanhoAcumulado += ganhoMensalAcumulado;
      newGanhosMensais.push(ganhoMensalComSaque);
      newGanhosAcumulados.push(ganhoMensalAcumulado);
      newSaldoGanhosAcumulados.push(valorInvestidoParaGanhoAcumulado);
    }

    setGanhosMensais(newGanhosMensais);
    setGanhosAcumulados(newGanhosAcumulados);
    setSaldoGanhosAcumulados(newSaldoGanhosAcumulados);
  };
  ///
  const handleSimulation = () => {};

  return (
    <div className="layout-content">
      <Row gutter={[24, 5]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Simule seus investimentos"
          >
            <Row justify="center">
              <Col span={4}>
                <Input
                  placeholder="Valor"
                  value={valorInvestimento}
                  onChange={handleChangeInvestimento}
                />
              </Col>
              <Col span={4}>
                <Input
                  placeholder="meses"
                  value={mesesInvestimento}
                  onChange={handleChangeMesesInvestimento}
                />
              </Col>
              <Col span={4}>
                <Button type="link" htmlType="button" onClick={calcularGanhos}>
                  Simular
                </Button>
              </Col>
            </Row>

            <table
              style={{
                width: "100%",
                border: "1px solid #555",
              }}
            >
              <tr>
                <th>Ganhos mensais com saque:</th>
                <th>Ganhos acumulados mês a mês:</th>
                <th>Saldo acumulado</th>
              </tr>
              <tr>
                <td>
                  {ganhosMensais.map((ganho, index) => (
                    <tr key={index}>
                      <td>{ganho.toFixed(2)}</td>
                    </tr>
                  ))}
                </td>
                <td>
                  {ganhosAcumulados.map((ganho, index) => (
                    <tr key={index}>
                      <td>{ganho.toFixed(2)}</td>
                    </tr>
                  ))}
                </td>
                <td>
                  {saldoGanhosAcumulados.map((ganho, index) => (
                    <tr key={index}>
                      <td>{ganho.toFixed(2)}</td>
                    </tr>
                  ))}
                </td>
              </tr>
            </table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Simulator;
