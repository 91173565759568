export const plan = (plan) => {
  switch (plan) {
    case 1:
      return 'Basico'
    case 2:
      return 'Medio'
    case 3:
      return 'PRO'
    default:
      return 'Nenhum'
  }
}
